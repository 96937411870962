import * as React from "react";
import { Link, graphql } from "gatsby";
import {
  Layout, SEO, CJumbotron, CBreadCrumb, LWrap, NavAbout, CDefinition
} from "../../../components/_index"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  console.log(data.markdownRemark)
  return (
    <Layout>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CJumbotron
        data={{
          title: {
            ja: '会社概要'
          },
          img: {
            src: '/assets/images/common/kv.png'
          },
          imgSp: {
            src: '/assets/images/common/kv__sp.png'
          }
        }}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: 'ロイヤルパークホテルズについて',
            path: '/about/'
          },
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <section className="l_sect02">
        <LWrap>
          <h2 className="c_headingLv2 u_tac">会社概要</h2>
          <CDefinition data={[
            {
              title: "商号",
              text: <>
                三菱地所ホテルズ＆リゾーツ株式会社<br />
                （Mitsubishi Estate Hotels & Resorts Co., Ltd.）
              </>
            },
            {
              title: "本店所在地",
              text: <>
                〒107-0062<br />
                東京都港区南青山1-1-1 新青山ビル東館13F
              </>
            },
            {
              title: "事業概要",
              text: <>
                ロイヤルパークホテルズの経営・運営他
              </>
            },
            {
              title: "資本金",
              text: <>
                100,000,000円
              </>
            },
            {
              title: "発行株式数",
              text: <>
                9,900株
              </>
            },
            {
              title: "株主構成",
              text: <>
                三菱地所（株）100％
              </>
            },
            {
              title: "設立年月日",
              text: <>
                平成12年11月1日
              </>
            },
            {
              title: "役員",
              text: <>
                <p className="u_mb20">代表取締役<br />社長執行役員<br />大島 正也</p>

                <p className="u_mb20">代表取締役<br />専務執行役員<br />藤 新治</p>
                <p className="u_mb20">取締役<br />専務執行役員<br />水上 義之</p>
                <p className="u_mb20">取締役<br />専務執行役員<br />雄城 隆史</p>
                <p className="u_mb20">取締役<br />常務執行役員<br />江川 巧</p>
                <p className="u_mb20">取締役（非常勤）<br />四塚 雄太郎</p>
                <p className="u_mb20">取締役（非常勤）<br />佐藤 元洋</p>
                <p className="u_mb20">取締役（非常勤）<br />金森 千佳</p>
                <p className="u_mb20">執行役員<br />小澤 幸一</p>
                <p className="u_mb20">執行役員<br />秋元 紳一郎</p>
                <p className="u_mb20">執行役員<br />松山 昌樹</p>
                <p className="u_mb20">執行役員<br />池田 一之</p>
                <p className="u_mb20">監査役<br />相川 雅彦</p>
                <p className="u_mb20">監査役（非常勤）<br />室星 吉郎</p>
                <p className="u_mt20 u_tar">（2024年4月1日現在）</p>
              </>
            },
          ]} />
        </LWrap>
      </section>
      <NavAbout />
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`